import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { ChangeEvent, useState } from 'react';

import { useMockEpisodeStore } from 'store/episode';

const EpisodeDevtools = () => {
  const [open, setOpen] = useState(false);
  const { setEpisode, episode } = useMockEpisodeStore((state) => ({
    setEpisode: state.setEpisode,
    episode: state.episode,
  }));
  const [startDate, setStartDate] = useState<Date | undefined>(episode?.start);
  const [endDate, setEndDate] = useState<Date | undefined>(episode?.end);

  const handleChangeStartDate = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(parseISO(event.target.value));
  };

  const handleChangeEndDate = (event: ChangeEvent<HTMLInputElement>) => {
    setEndDate(parseISO(event.target.value));
  };

  const handleSubmit = () => {
    if (startDate && endDate) {
      setEpisode({ start: startDate, end: endDate });
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton
        sx={{
          backgroundColor: 'primary.main',
          width: 40,
          height: 40,
          position: 'fixed',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          left: open ? 500 : 0,
          zIndex: 1000,
        }}
        onClick={() => setOpen((state) => !state)}
      >
        {'>'}
      </IconButton>
      <Drawer
        hideBackdrop
        sx={{
          zIndex: 999,
        }}
        PaperProps={{
          sx: {
            width: 500,
            padding: 4,
          },
        }}
        open={open}
      >
        <Typography variant="h1">Set custom episode dates</Typography>
        <Box width="100%" display="flex" gap={4}>
          <Box>
            <Typography>Start:</Typography>
            <input onChange={handleChangeStartDate} type="datetime-local" />
          </Box>
          <Box>
            <Typography>End:</Typography>
            <input onChange={handleChangeEndDate} type="datetime-local" />
          </Box>
        </Box>
        <Box mt={2} alignSelf="start">
          <Button onClick={handleSubmit}>Set dates</Button>
        </Box>
      </Drawer>
    </>
  );
};

export default EpisodeDevtools;
